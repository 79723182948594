import React, { lazy, Suspense, useEffect, useState } from "react";
import "./home.css";
import { motion } from "framer-motion";
import { slideIn, staggerContainer, textVariant } from "../../utils/motion";
import { Link } from "react-router-dom";

import { FaHeartPulse } from "react-icons/fa6";

import { homeContent } from "../../constants/data";
import HomePageMetadata from "../../components/generateMetadata/HomePageMetadata";
import FeaturedCampaigns from "../../components/FeaturedCampaigns";

const WaveSVG = lazy(() => import("../../components/waves"));

const ProgressiveImage = lazy(() =>
  import("../../components/ProgressiveImage")
);
const Slider = lazy(() => import("../../components/slider"));
const OurTeam = lazy(() => import("../../components/ourTeam"));
const Testimonials = lazy(() => import("../../components/testimonials"));

const UpcomingEvents = lazy(() => import("../../components/upcoming-events"));
const UpcomingEventsSkeleton = lazy(() =>
  import("../../components/skeletons/UpcomingEventsSkeleton")
);

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <HomePageMetadata />
      <section className="showcase">
        <div className="container">
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className="txtContent showcaseTxtContent"
          >
            <div className="txtHeader">
              <motion.h1 variants={textVariant(1.1)}>
                {homeContent.heroTitle}
              </motion.h1>
            </div>

            <motion.div variants={textVariant(1.2)} className="txt">
              <article>{homeContent.heroContent}</article>
            </motion.div>

            <motion.div variants={textVariant(1.3)} className="btnWrapper">
              <Link to={"/donate"} className="btnPrimary">
                Donate
              </Link>
            </motion.div>
          </motion.div>
        </div>

        <WaveSVG />
      </section>

      <section className="aboutSection pageSection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorRed">
                    {homeContent.whatWeDoSection.header}
                  </h2>
                </div>
                <div className="txt">
                  <article>{homeContent.whatWeDoSection.textContent}</article>
                </div>
                <div className="btnWrapper">
                  <Link to={"/about-us"} className="btnPrimary bkgTeal">
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imageWrapper">
                <ProgressiveImage
                  lowQualitySrc={
                    homeContent.whatWeDoSection.image.lowQualityImage
                  }
                  highQualitySrc={homeContent.whatWeDoSection.image}
                  alt={homeContent.whatWeDoSection.header}
                  className={"progressiveImage"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="eventsSection bkgLight pageSection"
        style={{ paddingTop: "0" }}
      >
        <div className="container">
          {isLoading ? (
            <>
              <UpcomingEventsSkeleton />
            </>
          ) : (
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <UpcomingEvents />
              </Suspense>
            </>
          )}
        </div>
      </section>

      <section className="hopeSection pageSection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="imageWrapper">
                <ProgressiveImage
                  lowQualitySrc={homeContent.hopeSection.image.lowQualityImage}
                  highQualitySrc={homeContent.hopeSection.image}
                  alt={homeContent.hopeSection.header}
                  className={"progressiveImage"}
                />
              </div>
            </div>
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorTeal">
                    {homeContent.hopeSection.header}
                  </h2>
                </div>
                <div className="txt">
                  <article>{homeContent.hopeSection.textContent}</article>
                </div>
                <div className="btnWrapper">
                  <Link to={"/donate"} className="btnPrimary bkgRed">
                    Donate
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="changingLives pageSection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="txtContent">
                <div className="txtHeader">
                  <h2 className="colorRed">
                    {homeContent.changingLivesSection.header}
                  </h2>
                </div>
                <div className="txt">
                  <article>
                    {homeContent.changingLivesSection.textContent}
                  </article>
                </div>
                <div className="btnWrapper">
                  <Link to={"/donate"} className="btnPrimary bkgTeal">
                    Donate
                  </Link>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imageWrapper">
                <ProgressiveImage
                  lowQualitySrc={
                    homeContent.changingLivesSection.image.lowQualityImage
                  }
                  highQualitySrc={homeContent.changingLivesSection.image}
                  alt={homeContent.changingLivesSection.header}
                  className={"progressiveImage"}
                />
              </div>
            </div>
          </div>
        </div>
        <Slider />
      </section>

      <FeaturedCampaigns homeContent={homeContent} />

      <section className="yourDonation pageSection bkgRed">
        <div className="container">
          <div className="txtContent">
            <div className="iconWrapper">
              <FaHeartPulse className="icon" />
            </div>
            <div className="txtHeader">
              <h3>
                Your donation means a lot to them. <br />
                Donate what you can.
              </h3>
            </div>
            <div className="btnWrapper">
              <Link to={"/donate"} className="btnPrimary">
                Donate
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="testimoniesSection pageSection">
        <div className="container">
          <Testimonials />
        </div>
      </section>

      <OurTeam />
    </>
  );
};

export default Home;
