import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import Home from "../pages/home/index";
import NotFoundPage from "../pages/not_found";
import ContactUs from "../pages/contact-us";
import Events from "../pages/events";
import Membership from "../pages/membership";
import AboutUs from "../pages/about-us";
import Programs from "../pages/programs";
import ComingEvents from "../pages/upcoming-events";
import EventDetailLayout from "../layouts/EventDetailLayout";
import EventDetail from "../pages/event-detail";
import PrivacyPolicy from "../pages/privacy-policy";
import CookiePolicy from "../pages/cookie-policy";
import TermsAndConditions from "../pages/terms-and-conditions";
import ScrollToTop from "../components/ScrollToTop";
import DonationSuccess from "../pages/donation-success";
import Donate from "../pages/donate";

const AppLayout = () => (
  <>
    <ScrollToTop />
    <Layout />
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "membership", element: <Membership /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "programs", element: <Programs /> },
      { path: "donate", element: <Donate /> },
      { path: "donation-success", element: <DonationSuccess /> },
      {
        path: "events",
        element: <EventDetailLayout />,
        children: [
          { index: true, element: <Events /> },
          { path: ":eventSlug", element: <EventDetail /> },
          {
            path: "upcoming-events/:upcomingEventSlug",
            element: <ComingEvents />,
          },
        ],
      },
      { path: "*", element: <NotFoundPage /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "cookie-policy", element: <CookiePolicy /> },
      { path: "terms-and-conditions", element: <TermsAndConditions /> },
    ],
  },
]);

export default router;
