import slideImg1 from "../assets/images/events/1.jpg";
import slideImg2 from "../assets/images/events/2.jpg";
import slideImg3 from "../assets/images/events/3.jpg";
import slideImg4 from "../assets/images/events/4.jpg";
import slideImg5 from "../assets/images/events/5.jpg";
import slideImg6 from "../assets/images/events/6.jpg";
import slideImg7 from "../assets/images/events/7.jpg";
import slideImg8 from "../assets/images/events/8.jpg";
import slideImg9 from "../assets/images/events/9.jpg";

import malik from "../assets/images/team/Malik.jpg";
import mabel from "../assets/images/team/Mabel.jpg";
import marilyn from "../assets/images/team/marilyn.jpg";
import zabari from "../assets/images/team/zabari1.jpg";
import rich from "../assets/images/team/rich2.jpg";
import Kissiwaa from "../assets/images/team/kisiwaa.JPG";
import AdjeiBoakye from "../assets/images/team/Adjei-Boakye.jpg";

import eventImg1 from "../assets/images/events/1.jpg";
import eventImg2 from "../assets/images/events/2.jpg";
import eventImg3 from "../assets/images/events/3.jpg";

import defaultImg1 from "../assets/images/default/book-mockup.jpg";
import defaultImg2 from "../assets/images/default/hes-logo-1.jpg";

import hopeImg from "../assets/images/hope.png";
import changeImg from "../assets/images/circular-image.png";
import hesIconImg from "../assets/images/web.png";

// export const donateContent = {
//   supportText:
//     "Mesh Of Mothers (MoM) is committed to providing a seamless and secure payment process to its clients. The following payment processes and procedures have been developed to ensure that all payments are processed accurately and efficiently.",
//   paymentMethodList: [
//     "Bank Account Transfer",
//     "Cheque",
//     "Email Money Transfer to donate@meshofmothers.ca",
//     "Other payment methods, as agreed upon between MoM and the client.",
//   ],
//   methods: [
//     {
//       title: "Bank Account Transfer",
//       description:
//         "Clients who wish to make payment via bank account transfer should follow these steps:",
//       icon: payMethodIcon1,
//       steps: [
//         "Contact MoM via email or phone to request the necessary bank account details.",
//         "Once the client receives the bank account details, they should initiate the transfer through their bank or online banking system.",
//         "The client should email a copy of the transfer confirmation to MoM at donate@meshofmothers.ca",
//         "Upon receipt of the transfer confirmation, MoM will confirm the receipt of payment and issue an invoice to the client.",
//       ],
//     },
//     {
//       title: "Cheque",
//       description:
//         "Clients who wish to make payment via cheque should follow these steps:",
//       icon: payMethodIcon2,
//       steps: [
//         'Make the cheque payable to "Mesh Of Mothers Foundation Inc".',
//         "Upon receipt of the cheque, MoM will confirm the receipt of payment and issue an invoice to the client.",
//       ],
//     },
//     {
//       title: "Email Money Transfer to",
//       description:
//         "Clients who wish to make payment via email money transfer should follow these steps:",
//       icon: payMethodIcon3,
//       steps: [
//         "Log in to their online banking system and navigate to the email money transfer section.",
//         "Add donate@meshofmothers.ca as a recipient and follow the prompts to complete the transfer.",
//         "In the message section, include the client's name, and any additional details.",
//         "Upon receipt of the transfer, MoM will confirm the receipt of payment and issue an invoice to the client.",
//       ],
//     },
//     {
//       title: "Other payment methods",
//       description:
//         "Clients who wish to make payment via other payment methods should contact MoM to agree on the payment process and procedure.",
//       icon: payMethodIcon4,
//       steps: [
//         "MoM will issue an invoice to the client once payment has been received.",
//         "If payment is not received within the agreed-upon timeframe, MoM will follow up with the client via email or phone to request payment.",
//         "If payment is still not received after two follow-ups, MoM may seek legal action to recover the outstanding amount.",
//       ],
//     },
//   ],
// };

export const homeContent = {
  heroTitle: "give the child the gift of education",
  heroContent: `Empower a child's future by supporting their educational journey. At Hestias Foundation, we believe that education is the most valuable gift we can give. Your contribution helps provide essential resources and opportunities for children in need, unlocking their potential and transforming their lives. Join us in making education a reality for every child.`,
  whatWeDoSection: {
    header: "What we do",
    textContent: `At Hestias Foundation, we are dedicated to one mission: ensuring less privileged children have access to education. We fight against the barriers that hold them back, providing the support and opportunities they need to learn, grow, and create a better future. Education is their right, and we are here to make it a reality.`,
    image: hesIconImg,
  },
  hopeSection: {
    header: "you're the hope of others",
    textContent: `At Hestias Foundation, we believe that while we are dedicated to helping less privileged children access education, you too are the hope for others. By connecting with us, you become part of a movement that empowers lives, breaks cycles of poverty, and builds brighter futures. Together, we create lasting change.`,
    image: hopeImg,
  },
  changingLivesSection: {
    header: "changing lives with knowledge",
    textContent: `At the heart of what we do at Hestias Foundation. We believe that education is the most powerful tool for transforming futures. By providing less privileged children with access to learning, we equip them with the knowledge to break free from poverty, empowering them to change their lives and their communities. Together, we are creating a world where knowledge leads to opportunity and lasting change.`,
    image: changeImg,
  },
  featuredCampaignSection: {
    header: "featured campaigns",
    textContent: `At Hestias Foundation, our featured campaigns highlight the urgent and impactful work we do to support less privileged children’s education. Each campaign is a step toward breaking the cycle of poverty and ensuring brighter futures. From providing school supplies and scholarships to building safe learning environments, our campaigns focus on real change through education. Join us in making a lasting difference—your support can transform lives.`,
  },
};

export const programsContent = {
  heroText: "programs",

  accordionData: [
    {
      title: "Education for all",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
    {
      title: "Every child deserves equal opportunity",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
    {
      title: "Food for all",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
    {
      title: "Clean water for schools",
      content: [
        {
          subtitle: null,
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
        {
          subtitle: "some title",
          items: [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores ipsa est sequi repellendus veritatis magni voluptas optio numquam? Accusamus, repudiandae.",
          ],
        },
      ],
    },
  ],
};

export const aboutUsContent = {
  heroText: "who we are",
  aboutSection: {
    aboutTextContent: `Hestias Foundation is a non-profit organization dedicated to breaking down barriers to education for underprivileged children. We provide essential support to those who face significant challenges in continuing their education. Our mission is to not only offer immediate assistance but also to inspire and empower these children to contribute back to their communities, fostering a cycle of support and positive change.
    
    At Hestias Foundation, we are dedicated to one mission: ensuring less privileged children have access to education. We fight against the barriers that hold them back, providing the support and opportunities they need to learn, grow, and create a better future. Education is their right, and we are here to make it a reality.`,
    visionStatement: `Empowering young minds to shape a brighter future, where every child has access to quality education and a nurturing environment to thrive.
`,
    missionStatement: `To transform lives by expanding access to superior education, advocating for children's well-being, and championing innovative approaches that address the multifaceted challenges of tomorrow. Our mission is to nurture communities where every child can realize their full potential and contribute to a sustainable and prosperous future.`,
    image: defaultImg1,
  },
  coreValuesSection: {
    valueHeader1: "Child-Centered Commitment",
    valueTextContent1: ` We prioritize the holistic development and well-being of children, ensuring their needs guide every decision we make.
`,
    valueHeader2: "Universal Access to Education",
    valueTextContent2: ` We are dedicated to the principle that high-quality education is an inalienable right, transcending socio-economic, cultural, and geographical boundaries
`,
    valueHeader3: "Innovation-Driven Impact",
    valueTextContent3: ` We harness the power of creativity and forward-thinking collaborations to pioneer solutions that create lasting, positive change.

`,
    valueHeader4: "Empowerment Through Education",
    valueTextContent4: ` We believe in equipping children, families, and communities with the knowledge, skills, and resources necessary to drive their own success and resilience.

`,
    valueHeader5: "Compassionate Action",
    valueTextContent5: ` Our approach is rooted in empathy and kindness, with a steadfast commitment to making a meaningful difference in the lives we touch.
`,
  },
};

export const membershipContent = {
  heroText: "Membership",
  familySection: {
    header: "we are \n family",
    subTitle: "Together we can do more",
    textContent: `Join Hestias Foundation as a member and be part of a transformative movement. Your membership empowers us to reach more children, expand our programs, and create greater impact. Together, we can achieve more, break down barriers, and ensure that every child has the opportunity for a brighter future. Your support is crucial—let’s make a difference together.`,
    image: defaultImg1,
  },
  volunteerSection: {
    header: "volunteer",
    subTitle: "Extend a helping hand",
    textContent: `Lend your support and make a difference with Hestias Foundation. By volunteering, you help us provide crucial educational opportunities to less privileged children. Extend a helping hand and be part of a meaningful journey towards a brighter future for all.`,
    image: defaultImg2,
  },
};

const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

export const event = [
  {
    id: "1",
    image: defaultImg1,
    title: "Hope for change",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Kwame Danso",

    date: {
      day: "14",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
    ],
  },
  {
    id: "2",
    image: defaultImg2,
    title: "Making a difference in kids",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Adugyamah",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
  {
    id: "3",
    image: defaultImg1,
    title: "Samson Tukor goes to school again",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Adugyamah",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
  {
    id: "4",
    image: defaultImg2,
    title: "Students of Yaw Preko M/A can now study without fear of rain",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Yaw Preko",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
  {
    id: "4",
    image: defaultImg1,
    title: "Donated 1000 exercise books to D/A school",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.",
    location: "Morokrom",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
  },
  {
    id: "5",
    image: eventImg1,
    title: "Every child deserves an education",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio.",
    location: "Kwame Danso",
    date: { day: "14", month: "september", year: "2022" },
    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg1 },
      { img: defaultImg1 },
      { img: defaultImg1 },
    ],
    featured: true,
  },
  {
    id: "6",
    image: eventImg2,
    title: "Akosua Bompata can now boast of a school uniform and desk",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio.",
    location: "Adugyamah",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
    featured: true,
  },
  {
    id: "7",
    image: eventImg3,
    title: "Students of Yaw Preko M/A can now study without fear of rain",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio.",
    location: "Yaw Preko",
    date: { day: "19", month: "september", year: "2022" },
    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: defaultImg2 },
      { img: defaultImg2 },
      { img: defaultImg2 },
    ],
    featured: true,
  },
];

export const events = shuffle(event);

export const upcomingEvents = [
  {
    id: "1",
    image: defaultImg2,
    title: "School for all",
    details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.
      
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.  `,
  },
  {
    id: "2",
    image: defaultImg1,
    title: "Books for all",
    details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.

    Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
  },
];

// export const programsContent = {
//   list: [
//     {
//       title: "Psycho-education and awareness",
//       description:
//         "The Psychoeducation and Awareness Program for Mesh Of Mothers (MoM) is a comprehensive initiative designed to provide mental health education, support, and empowerment for black mothers within the community. This program aims to address the unique challenges and experiences faced by black mothers while promoting their emotional well-being, resilience, and self-care.",
//     },
//     {
//       title: "Maternal Mentorship",
//       description:
//         "The Maternal Mentorship Program for Mesh of Mothers (MoM) is a transformative initiative designed to provide guidance, support, and empowerment to black mothers within the community. This program aims to create a network of experienced mentors who can offer guidance, share knowledge, and serve as role models for black mothers navigating various aspects of motherhood.",
//     },
//     {
//       title: "Resource finding",
//       description:
//         "The Resource Finding Program for Mesh Of Mothers (MoM) is a vital initiative designed to connect black mothers with essential resources, services, and support systems within the community. This program aims to address the unique needs and challenges faced by black mothers by providing them with access to a wide range of resources that promote their well-being, empowerment, and overall success.",
//     },
//     {
//       title: "Mommy & Me programs / parenting programs",
//       description:
//         "The Mommy & Me/Parenting Program for Mesh Of Mothers (MoM) is a valuable initiative designed to provide a supportive and enriching environment for black mothers and their children. This program focuses on nurturing the parent-child bond, enhancing parenting skills, and fostering holistic child development within the black community. ",
//     },
//     {
//       title: "Community innitiatives",
//       description:
//         "The Community Initiatives Program for Mesh Of Mothers (MoM) is a dynamic and inclusive initiative designed to engage and uplift the black mothers in the community. This program aims to foster a sense of belonging, provide opportunities for personal growth, and promote positive social change through various community-based initiatives.",
//     },
//   ],
// };

export const sliderImages = [
  {
    image: slideImg1,
  },
  {
    image: slideImg2,
  },

  {
    image: slideImg3,
  },
  {
    image: slideImg4,
  },
  {
    image: slideImg5,
  },
  {
    image: slideImg6,
  },
  {
    image: slideImg7,
  },
  {
    image: slideImg8,
  },
  {
    image: slideImg9,
  },
  {
    image: slideImg5,
  },
];

export const testimonies = {
  testimonyHeader: "what people say !  ",
  testimonyTitle: "testimonials",
  testimonials: [
    {
      id: "0",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Ruth Akotompaya",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "1",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Asabea Jude",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "2",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Yaw Bompata",
      role: " beneficiary",
      avatar: "",
    },
    {
      id: "3",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Black Moses ",
      role: " Beneficiary",
      avatar: "",
    },
    {
      id: "4",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Hajia Bintuphobia ",
      role: "Beneficiary",
      avatar: "",
    },
    {
      id: "5",
      testimony: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "Jane Doe ",
      role: " Beneficiary",
      avatar: "",
    },
    {
      id: "6",
      testimony: ` Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero corporis ratione sit enim minima magnam aperiam dolorum consequatur, optio odio. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa quia sunt, pariatur iure magni deserunt tempora incidunt velit! Quo.`,
      testifier: "John Doe ",
      role: " Beneficiary",
      avatar: "",
    },
  ],
};

export const teamMembers = [
  {
    id: "1",
    name: "Richard Anane Asiamah",
    role: "Founder and Director",
    image: rich,

    info: `Richard Anane Asiamah is the founder and director of the Hestias Foundation, an organization dedicated to advancing educational opportunities for children in Ghana. After working as a teacher in Ghana and now residing in the UK, Richard has witnessed the profound impact that financial and logistical challenges can have on a child's ability to attend school.

Motivated by a deep concern for these vulnerable children who were forced to leave school due to lack of support, Richard established the Hestias Foundation. The organization aims to provide crucial educational assistance to those in need, ensuring that every child in Ghana has the opportunity to pursue their education and achieve their full potential.

Richard's vision is not only to help these children succeed but also to inspire a future generation that will, in turn, support others. Through the Hestias Foundation, Richard is dedicated to fostering a culture of mutual aid and community support, embodying the principle that by helping one another, we can build a brighter future for all.`,
  },
  {
    id: "2",
    name: "Anacetus Zabari Jnr",
    role: "HR Manager",
    image: zabari,

    info: `A creative and innovative leader with expertise in business management, customer engagement, and business development. His vision is to drive human capital development by unlocking the potential of young people, fostering true leadership, education, and entrepreneurship. As an aspiring entrepreneur, educator, and impact speaker, Anacetus is committed to developing innovative solutions to empower communities. His strong communication and relationship management skills, alongside his proficiency in leveraging social media for growth, align perfectly with Hestias Foundation's mission to provide education for underprivileged children, helping them grow and give back to those in similar situations.`,
  },
  {
    id: "3",
    name: "Marilyn Asuamah",
    role: "Director",
    image: marilyn,

    info: `Marilyn Asuamah is a director at the Hestias Foundation, bringing her dedication and expertise as a UK-based nurse and continuing student to the organization. With a deep commitment to the mission of Hestias Foundation, Marilyn plays a crucial role in advancing the charity's goals of providing educational support to children in Ghana.

Her background in nursing and ongoing academic pursuits reflect her passion for service and personal growth. Marilyn's work with the Hestias Foundation aligns with her belief in the transformative power of education and her desire to make a meaningful impact on the lives of children facing educational barriers.

Through her role, Marilyn contributes to the foundation's efforts to ensure that every child in Ghana has access to the education they deserve, fostering a cycle of support and empowerment that extends beyond the classroom.`,
  },

  {
    id: "4",
    name: "Malik Benjamin",
    role: "Program Director",
    image: malik,

    info: `Malik Benjamin is a passionate and driven leader with a BSc in Biological Sciences. Currently serving as the Program Director of Hestias Foundation, he brings exceptional leadership and a deep commitment to empowering underprivileged children. Alongside his role, Malik is the President of the National Service Personnel Association, Tema West Municipal, demonstrating his dedication to community service. Beyond his professional life, he is a creative individual who expresses himself through music and personal growth, always striving for greatness and inspiring those around him.`,
  },
  {
    id: "5",
    name: "Mabel Obour",
    role: "General Secretary",
    image: mabel,

    info: ` As the General Secretary of Hestias Foundation, I am passionate about supporting and uplifting underprivileged children, helping them reach their full potential. Alongside my work as a midwife, I am deeply committed to the foundation's mission. In this role, I ensure the smooth operation of our programs and contribute to the positive impact we strive to make in the lives of the children we serve.`,
  },
  {
    id: "6",
    name: "Belinda Adjei-Boakye",
    role: "Assistant HR",
    image: AdjeiBoakye,

    info: ` Belinda Adjei-Boakye is a graduate and entrepreneur specializing in wig-making and perfumery. She holds a Bachelor's degree in Political Science with Social Studies Education from the University of Education, Winneba. Passionate about cooking, helping others, and music, Belinda aligns with Hestias Foundation's mission to support underprivileged children through education, encouraging them to give back to their communities. She now contributes to this vision as the Assistant HR at Hestias Foundation.`,
  },
  {
    id: "7",
    name: "Belinda Kissiwaa",
    role: "Financial Manager",
    image: Kissiwaa,

    info: ` Belinda Kissiwaa is a hardworking, kind, and dedicated individual serving as the Financial Manager of Hestias Foundation. Inspired to support underprivileged children in their academic journeys, she brings her commitment to education alongside her profession as a nurse. Belinda ensures that her contributions align with the foundation’s vision and mission to empower less privileged children, encouraging them to give back to their communities.`,
  },
];
