import React, { lazy } from "react";
import "./programs.css";
import { programsContent } from "../../constants/data";
import ProgramsMetadata from "../../components/generateMetadata/ProgramsMetadata";

const Accordion = lazy(() => import("../../components/accordion"));

const Programs = () => {
  return (
    <>
      <ProgramsMetadata programsContent={programsContent} />
      <section className="programsHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>{programsContent.heroText}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="programsList pageSection">
        <div className="container">
          <div className="packages">
            {programsContent.accordionData.map((accordion, index) => (
              <Accordion key={index} title={accordion.title}>
                {accordion.content.map((section, sectionIndex) => (
                  <div key={sectionIndex} className="accItem">
                    {section.subtitle && (
                      <h5 className="accBullet">{section.subtitle}</h5>
                    )}
                    <ul className="accList">
                      {section.items.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Accordion>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Programs;
